body {
	overflow-x: hidden;
}

.wbg {
	background: $white;
	padding: 0 ($spacer * 2);
}


//Utilites

.mt10 {
	margin-top: 1rem;
}
.mt20 {
	margin-top: 2rem;
}

// Scrollup
.scrollup {
  position: fixed;
  bottom: ($spacer * 3);
  right: ($spacer * 3);
  display: none;
  color: $white;
  @extend .btn;
  @extend .btn-mint;
  @include border-radius(0);
}


input[type='text'],
input[type='number'],
input[type='password'],
textarea {

}


.breadcrumb-item {
	a {
		color: $link-color;
	}
}

//Buttons

.btn-rounded {
	border-radius: 5rem;
}
.btn-sharp {
	@include border-radius(0);
}
.btn-bordered {
	border-width: 0.16rem;
}
.btn-bp {
	padding-left: 1.75em;
	padding-top: 0.75rem;
	padding-right: 1.75em;
	padding-bottom: 0.75rem;
}
.btn-wtxt {
	color: $white;
}


.offcanvas-nav .form-inline { 
	display: none;
}
.is-hidden {
	visibility: hidden;
}

.offcanvas-nav {
	&.d-md-none,
	&.d-lg-none {
		width: 100%;
		.navbar-nav {
			display: block;
			width: 100%;
		}
	}
}

.topbar {
	background: $blue;
	color: $white;
	padding-top: $spacer;
	padding-bottom: $spacer;
	margin-bottom: ($spacer * 2);
}

// Sections
section {

	@extend .clearfix;

  h2 {
    margin-bottom: ($spacer * 2);
    font-size: 2rem;
  }

	&.intro {
		margin-top: ($spacer * 3);
		margin-bottom: ($spacer * 3);

		img,
		a > img {
			@extend .img-fluid;
		}

		h2 {
			@extend .font-weight-bold;
			font-size: 1.6rem;
			margin: 0;

			a {
				color: $yellow;
				text-decoration: none;
				//box-shadow: inset 0 -10px 0 $yellow;
			}
		}

	}

	&.advantage {
		background: $gray-100;
		padding-top: ($spacer * 3);
		padding-bottom: ($spacer * 3);
		margin-bottom: ($spacer * 3);

		@extend .text-center;
	}

	&.advantage2 {
		margin-top: ($spacer * 3);
		margin-bottom: ($spacer * 3);
		border-bottom: 1px solid $gray-300;
		padding-bottom: ($spacer * 3);

		@extend .text-center;

		.title {
			@extend .font-weight-bold;
			@extend .mt-3;
			@extend .mb-3;
			font-size: 1.2rem;

		}
		.desc {
			font-size: .850rem;
		}
	}

	&.services,
	&.about {
		margin-top: ($spacer * 3);
		margin-bottom: ($spacer * 3);
		border-bottom: 1px solid $gray-300;

		img,
		a > img {
			@extend .img-fluid;
		}

		h2 {
			@extend .font-weight-bold;
			font-size: 1.6rem;
			margin: 0;

			a {
				color: $yellow;
				text-decoration: none;
				//box-shadow: inset 0 -14px 0 $yellow;
			}

		}

		p {
			margin-top: ($spacer * 1);
		}		
	}

	&.services {
		margin-bottom: 0;
	}


	&.offer {
		margin-bottom: ($spacer * 3);
		background: $blue;
		color: $white;
    .offer-decor {
      background: $blue url(../img/bg-offer.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      color: $white; 
      min-height: 500px; 



      .inner {
        @extend .w-75;
        @extend .mx-auto;
        @extend .text-center;
        font-size: 2rem;
        border: 6px solid $white;
        padding: 3rem;

        p {
          font-size: 1.8rem;
          color: $white;
          @extend .font-weight-bold;
        }

        @include media-breakpoint-down(xs) {
					padding: 0;
        }
      }
    }

		a {
			color: $white;
			@extend .font-weight-bold;
		}
	}

	&.specialists {

		margin-top: ($spacer * 3);
		margin-bottom: ($spacer * 3);

		padding-top: ($spacer * 3);

		img,
		a > img {
			@extend .img-fluid;
		}

		.filled {
			background: $white;
			border-top: 1px solid $gray-500;
			padding-top: ($spacer * 1);
		}

	}

  &.free-consult {
  	@extend .position-relative;

    .section-header {      
      padding-top: ($spacer * 4);
      padding-bottom: ($spacer * 10);
      @extend .text-center;
      background: $blue;
      color: $white;
      //margin-bottom: ($spacer * 4);

      h2 {
        color: $white;
      }
    }

    .consult-form {
      @extend .position-relative;
      top: -($spacer * 6);
      bottom: -($spacer * 10); 

      .wbg {
        padding-top: ($spacer * 2);
      }
      
    }
  }

	&.articles {
		margin-bottom: ($spacer * 3);

		img,
		a > img {
			@extend .img-fluid;
		}

		p {
			margin-top: ($spacer * 1);
			@extend .font-weight-bold;
		}		
	}

  &.testimonials {
  	.section-header {
  		@extend .text-center;
  	}
    padding-top: ($spacer * 3);
    padding-bottom: ($spacer * 3);
		img,
		a > img {
			@extend .img-fluid;
		}
		.owl-nav {
			margin-top: ($spacer * 1);
			@extend .text-center;
			font-size: 1.6rem;

			button {
				margin-left: .65rem;
				margin-right: .65rem;
			}

		}

		.swiper-slide {
			padding-left: 3rem;
			padding-right: 3rem;
		}

		.rating {
			color: $orange;
			@extend .text-right;
			
			span {
				padding-right: .10rem
			}		
		}
  }

  &.support {
  	@extend .text-center;
  }

}


// Footer
.footer {
	@extend .clearfix;
  padding-top: ($spacer * 4);


  a > img {
  	 @extend .img-fluid;
    max-width: 60%;
    max-height: 60%;
  }

}
